
import { Component,Vue } from 'vue-property-decorator'
import { deleteMenu, loadMenus, sync } from "@/api/system/menu"; 
import { isExternal } from '@/utils/validate'
import path from 'path'
import Order from './components/order.vue';

@Component({
    name: 'Menus',
    components: {
        Order
    }
})
export default class extends Vue {
  listLoading = true
  total = 0
  list = []
  keyword = null
  dialogOrderVisible = false
  infoParams:any = {}

  get routes() {
    return (this.$router as any).options.routes
  }

  mounted() {
    this.getList()
  }
  //初始加载数据
  handleFilter() {
    this.getList()
  }

  //加载api数据
  getList(){
    this.listLoading = true
    loadMenus().then(response => {
        this.list = response.data
        // Just to simulate the time of the request
        setTimeout(() => {
          this.listLoading = false
        }, 500)
      })
  }

  //同步菜单数据
  handleMenuSync(){
    this.listLoading = true
    const param = [] as any[]
    for (const routeItem of this.routes) {
      if (routeItem.meta && routeItem.meta.hidden) continue;
      const pushRouteItem = this.buildRouteData(routeItem,null);
      if(pushRouteItem != null){
        if (pushRouteItem.children && pushRouteItem.children.length === 1) {
          param.push(pushRouteItem.children[0])
        } else {
          param.push(pushRouteItem)
        }
      }
    }
    sync({ routes: param }).then(response => {
      setTimeout(() => {
        this.listLoading = false
      }, 500)
      this.getList()
    })
  }

  buildRouteData(routeItem:any,prePath:any){
    if (routeItem.meta && routeItem.meta.hidden) {
        return null;
    }
    const menuItem = {
      title: routeItem.meta ? routeItem.meta.title : null,
      icon: routeItem.meta ? routeItem.meta.icon : null,
      jump: this.resolvePath(prePath,routeItem.path),
      children: [] as any[]
    }
    if(routeItem.children && routeItem.children.length > 0){
      const showingChildren = routeItem.children.filter((item: any) => {
        if (item.meta && item.meta.hidden) {
          return false
        } else {
          return true
        }
      })
      for (const child of showingChildren) {
        const pushChild = this.buildRouteData(child,menuItem.jump);
        if(pushChild != null){
          menuItem.children.push(pushChild)
        }
      }
    }
    return menuItem;
  }

  resolvePath(prePath:string,routePath: string) {
    if(prePath === null || prePath === undefined) return routePath;
    if (isExternal(routePath)) {
      return routePath
    }
    if (isExternal(prePath)) {
      return prePath
    }
    return path.resolve(prePath, routePath)
  }

  //修改排序
  handlePermissionRole(row:any){
    this.infoParams = row
    this.dialogOrderVisible = true
  }

  //删除菜单
  deleteMenu(code:string){
    this.$confirm('确定删除当前接口?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteMenu({ code }).then(response => {
          this.$message({
              type: 'success',
              message: '操作成功!'
            });
          this.getList()
        }).catch(()=>{})
      }).catch(() => {});
  }
}
