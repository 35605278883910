
import { order } from '@/api/system/menu';
import { cloneDeep } from '@/utils/common';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
    name: 'Order'
})
export default class Order extends Vue {
    @Prop({ required: true, default: false }) dialogUpdateVisible!: boolean;
    @Prop({ required: true }) readonly infoParams!: any;
    
    get dialogVisible(){
      return this.dialogUpdateVisible;
    }

    set dialogVisible(val){
      this.$emit('update:dialogUpdateVisible', val)
    }
     
    formParam = {
        code: null,
        title: null,
        order: null
    }

    @Emit("getList")
    getList(){}

    handleUpdate(){
        order({ code: this.formParam.code, order: this.formParam.order }).then(res => {
            this.$message({
                type: 'success',
                message: '操作成功!'
            });
            this.dialogVisible = false
            this.getList();
        })
    }

    @Watch("infoParams")
    infoParamsChange(){
        this.formParam = cloneDeep(this.infoParams)
    }
}
